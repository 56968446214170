<script setup>
const props = defineProps(['cartProduct']);
const emit = defineEmits(['onDelete', 'onChangeQuantity']);
const formatters = useFormatters();

const product = props.cartProduct.product;

const showSubtype = product.subtype != null;
let modNames = [];
if (showSubtype) {
    modNames.push(product.subtype);
}

modNames.push(...props.cartProduct.modifiers_names);

const modifiersString = modNames.join(", ");

const onDelete = () => {
    emit("onDelete", props.cartProduct);
};

const onChangeQuantity = (value) => {
    emit("onChangeQuantity", props.cartProduct, value);
};
</script>

<template>
    <div class="product-card">
        <div class="d-flex flex-row">
            <div class="product-card__image-container">
                <div class="product-image ratio ratio-1x1" :style="{ 'background-image': `url(${product.image_url})` }">
                </div>
            </div>
            <div class="product-card__info">
                <div class="product-name"> {{ product.name_with_size }}</div>
                <p>{{ modifiersString }}</p>
            </div>
        </div>
        <div class="product-card__bottom">
            <div class="price">
                {{ formatters.fixedWhenNeeded(cartProduct.total, 2) }}₽
                <div v-if="cartProduct.discount > 0" class="discount">
                    {{ formatters.fixedWhenNeeded(cartProduct.total + cartProduct.discount, 2) }}₽</div>
                <div v-if="cartProduct.discount_text" class="discount-badge">
                    {{ cartProduct.discount_text }}
                </div>
            </div>
            <div v-if="!cartProduct.gift" class="spin">
                <div @click="onChangeQuantity(-1)" class="minus">
                    <UiIconsCartMinusIcon />
                </div>
                <div class="value">{{ cartProduct.quantity }}</div>
                <div @click="onChangeQuantity(1)" class="plus">
                    <UiIconsCartPlusIcon />
                </div>

            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
.product-card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    overflow: hidden;

    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    .delete-button {

        position: absolute;
        top: -40px;
        right: -40px;
        width: 40px;
        height: 40px;
        background-color: #FAFAFA;
        border-radius: 0px 0px 0px 20px;
        padding: 4px;

        line-height: 0;
        text-align: center;
        cursor: pointer;
        z-index: 100;
        transition: all 0.4s;

        i {
            position: relative;
            top: 7px;
            left: 2px;
            height: 20px;
            vertical-align: middle;
            opacity: 0;
            font-size: 0px;
            transition: all 0.2s;
        }
    }

    &:hover {
        .delete-button {
            display: block;
            top: 0px;
            right: 0px;

            transition: all 0.4s;

            i {
                font-size: 14px;
                opacity: 1;
                transition: all 0.2s;
            }
        }
    }

    &__image-container {
        width: 100%;
        max-width: 80px;

        .product-image {
            background-size: cover;
            border-radius: 10px;
            background-position: 50% 50%;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 10px;
        padding-top: 5px;

        p {
            color: #A1A1A1;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
        }

        .product-name {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 1.1;
            margin-bottom: 10px;
        }
    }

    &__bottom {
        margin-top: 10px;
        // padding-top: 17px;
        // border-top: 1px solid #a1a1a152;
        display: flex;
        flex-direction: row;

        .price {
            display: flex;
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 1;
            margin-top: auto;
            margin-right: auto;
            margin-left: 10px;

            .discount-badge {
                font-size: 12px;
                background-color: #0073AB;
                padding: 4px 7px;
                border-radius: 20px;
                vertical-align: middle;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 8px;
                color: #fff;
            }

            .discount {
                position: relative;
                color: #646464;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                margin-top: auto;
                margin-left: 6px;
                margin-bottom: 2px;

                &:before {
                    content: " ";
                    display: block;
                    width: 100%;
                    border-top: 1px solid rgba(red, 0.8);
                    height: 12px;
                    position: absolute;
                    top: 6px;
                    left: 0;
                    transform: rotate(-10deg);
                }
            }
        }

        .spin {
            display: flex;
            height: 36px;
            border-radius: 40px;
            border: if($is-domino, 1px solid $primary, 1px solid #000);
            margin-top: auto;
            margin-bottom: auto;

            .minus,
            .plus {
                position: relative;
                top: -1px;
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                transition: all 0.3s;
                cursor: pointer;
                opacity: 1;
                margin: auto 6px;

                &:hover {
                    opacity: 0.6;
                    transition: all 0.3s;
                }

                img {
                    width: 100%;
                }
            }

            .value {
                width: 30px;
                text-align: center;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: auto;
                margin-right: auto;

                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: if($is-domino, $primary, #000);
                ;
            }
        }
    }
}
</style>